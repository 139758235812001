<template>
  <div>
    <b-card title="Beavatkozásra váró foglalások" v-if="$acl.canListPermission('reservation')">
      <b-row>
        <b-col md="12">
          <b-alert v-if="requestStatusReservations > 0 || conditionalStatusReservations > 0"
                   variant="warning"
                   class="mb-2"
                   :show="true"
          >
            <div class="alert-body">
              <feather-icon
                  icon="AlertTriangleIcon"
                  class="mr-50"
              />
              Jelenleg {{ requestStatusReservations }} db kérelem és {{ conditionalStatusReservations }} db
              feltételesen jóváhagyott kérelem vár admin jóváhagyásra. <br>
              <b-button
                  class="mt-2"
                  size="sm"
                  variant="warning"
                  @click.stop="$router.push({ name: 'reservations', query: {onlyTodo: 'true'}})"
              >Tovább
              </b-button>
            </div>
          </b-alert>
          <b-alert v-else
                   variant="success"
                   class="mb-2"
                   :show="true"
          >
            <div class="alert-body">
              <feather-icon
                  icon="AlertTriangleIcon"
                  class="mr-50"
              />
              Jelenleg nincs admin jóváhagyásra váró helyfoglalás.<br>
              <b-button
                  class="mt-2"
                  size="sm"
                  variant="success"
                  @click.stop="$router.push({ name: 'reservations'})"
              >Tovább
              </b-button>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-card>

    <b-card title="Naponkénti összesítő diagram" v-if="$acl.canListPermission('reservation')">
      <b-row>
        <b-col md="12">
          <b-overlay
              :show="reservationLoading"
              spinner-variant="primary"
              spinner-type="grow"
              rounded="sm"
          >
            <chart-js-component-bar-chart
                ref="reservationChart"
                :height="400"
                :data="reservation.stackedChart.data"
                :options="reservation.stackedChart.options"
                :plugins="reservation.stackedChart.plugins"
            />
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>

    <b-card title="Új üzenetek" v-if="$acl.canAdminPermission('message')">
      <b-row>
        <b-col md="12">
          <b-alert v-if="messages > 0"
                   variant="warning"
                   class="mb-2"
                   :show="true"
          >
            <div class="alert-body">
              <feather-icon
                  icon="AlertTriangleIcon"
                  class="mr-50"
              />
              Jelenleg {{ messages }} db új üzenet vár válaszra.<br>
              <b-button
                  class="mt-2"
                  size="sm"
                  variant="warning"
                  @click.stop="$router.push({ name: 'messages'})"
              >Tovább
              </b-button>
            </div>
          </b-alert>
          <b-alert v-else
                   variant="success"
                   class="mb-2"
                   :show="true"
          >
            <div class="alert-body">
              <feather-icon
                  icon="AlertTriangleIcon"
                  class="mr-50"
              />
              Jelenleg nincs új üzenet.<br>
              <b-button
                  class="mt-2"
                  size="sm"
                  variant="success"
                  @click.stop="$router.push({ name: 'messages'})"
              >Tovább
              </b-button>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-card>

    <b-card title="Új fotóalbumok" v-if="$acl.canListPermission('gallery')">
      <b-row>
        <b-col md="12">
          <b-alert v-if="galleries > 0"
                   variant="warning"
                   class="mb-2"
                   :show="true"
          >
            <div class="alert-body">
              <feather-icon
                  icon="AlertTriangleIcon"
                  class="mr-50"
              />
              Jelenleg {{ galleries }} db fotóalbum vár jóváhagyásra.<br>
              <b-button
                  class="mt-2"
                  size="sm"
                  variant="warning"
                  @click.stop="$router.push({ name: 'galleries'})"
              >Tovább
              </b-button>
            </div>
          </b-alert>
          <b-alert v-else
                   variant="success"
                   class="mb-2"
                   :show="true"
          >
            <div class="alert-body">
              <feather-icon
                  icon="AlertTriangleIcon"
                  class="mr-50"
              />
              Jelenleg nincs jóváhagyásra váró fotóalbum.<br>
              <b-button
                  class="mt-2"
                  size="sm"
                  variant="success"
                  @click.stop="$router.push({ name: 'galleries'})"
              >Tovább
              </b-button>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-card>

    <div v-if="polls.length">
      <b-card
          v-for="poll in polls"
          :key="poll.id"
          :title="poll.title"
          v-if="$acl.canListPermission('poll')"
      >
        <b-row>
          <b-col md="12">
            <b-overlay
                :show="pollsAreLoading"
                spinner-variant="primary"
                spinner-type="grow"
                rounded="sm"
            >
              <chart-js-component-bar-chart
                  :ref="'poll-chart-'+poll.id"
                  :height="400"
                  :data="poll.chart.data"
                  :options="poll.chart.options"
                  :plugins="poll.chart.plugins"
              />
            </b-overlay>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {BCol, BRow, BCard, BCardText, BLink, BButton, BAlert, BOverlay} from 'bootstrap-vue'
import ChartJsComponentBarChart from '@/views/pages/_components/_dashboard/BarChart.vue'
import {$themeColors} from "@themeConfig";

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BCardText,
    BLink,
    BButton,
    BAlert,
    BOverlay,
    ChartJsComponentBarChart
  },
  data() {
    const chartColors = {
      successColor: '#28c76f',
      warningColor: '#ff9f43',
      dangerColor: '#ea5455',
      secondaryColor: '#82868b',
      infoColor: '#00cfe8',
      tooltipShadow: 'rgba(0, 0, 0, 0.25)',
      labelColor: '#6e6b7b',
      gridLineColor: 'rgba(200, 200, 200, 0.2)',
      polls: [
        '#091A2E',
        '#003731',
        '#A55A0D',
        '#214D7A',
        '#156C63',

        '#172D44',
        '#0C524B',
        '#D4791D',
        '#38638F',
        '#298279',

        '#273c54',
        '#216a63',
        '#e99846',
        '#547ca5',
        '#42968e',

        '#3C536C',
        '#3C827B',
        '#FFB76E',
        '#80A2C6',
        '#6AB5AD',

        '#5A6E85',
        '#639D97',
        '#FFCC99',
        '#B4CBE3',
        '#A2D8D2'
      ]
    }

    return {
      chartColors: chartColors,
      reservationLoading: false,
      pollsAreLoading: false,
      requestStatusReservations: 0,
      conditionalStatusReservations: 0,
      reservation: {
        statuses: {
          request: 0,
          conditional: 0,
          confirmed: 0,
          canceled: 0,
          deleted: 0,
        },
        stackedChart: {
          data: {
            labels: [],
            datasets: [
              {
                data: [],
                label: 'Kérelem',
                backgroundColor: chartColors.dangerColor,
                borderColor: 'transparent',
              },
              {
                data: [],
                label: 'Feltételes',
                backgroundColor: chartColors.warningColor,
                borderColor: 'transparent',
              },
              {
                data: [],
                label: 'Visszaigazolt',
                backgroundColor: chartColors.successColor,
                borderColor: 'transparent',
              },
              {
                data: [],
                label: 'Lemondott',
                backgroundColor: chartColors.infoColor,
                borderColor: 'transparent',
              },
              {
                data: [],
                label: 'Törölt',
                backgroundColor: chartColors.secondaryColor,
                borderColor: 'transparent',
              }
            ],
          },
          options: {
            elements: {
              rectangle: {
                borderWidth: 2,
                borderSkipped: 'bottom',
              },
            },
            hover: {
              mode: 'label',
            },
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            legend: {
              display: true,
              position: 'top',
              align: 'start',
              labels: {
                usePointStyle: true,
                padding: 25,
                boxWidth: 9,
              },
            },
            tooltips: {
              // Updated default tooltip UI
              shadowOffsetX: 1,
              shadowOffsetY: 1,
              shadowBlur: 8,
              shadowColor: chartColors.tooltipShadow,
              backgroundColor: $themeColors.light,
              titleFontColor: $themeColors.dark,
              bodyFontColor: $themeColors.dark,
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  stacked: true,
                  gridLines: {
                    display: true,
                    color: chartColors.gridLineColor,
                    zeroLineColor: chartColors.gridLineColor,
                  },
                  scaleLabel: {
                    display: false,
                  },
                  ticks: {
                    fontColor: chartColors.labelColor,
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  stacked: true,
                  scaleLabel: {
                    display: true,
                  },
                  gridLines: {
                    color: chartColors.gridLineColor,
                    zeroLineColor: chartColors.gridLineColor,
                  },
                  ticks: {
                    stepSize: 1,
                    min: 0,
                    max: 0,
                    fontColor: chartColors.labelColor,
                  },
                },
              ],
            },
          },
          plugins: [
            // to add spacing between legends and chart
            {
              beforeInit(chart) {
                /* eslint-disable func-names, no-param-reassign */
                chart.legend.afterFit = function () {
                  this.height += 20
                }
                /* eslint-enable */
              },
            },
          ],
        }
      },
      messages: 0,
      galleries: 0,
      polls: [],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {

      this.reservationLoading = true
      this.pollsAreLoading = true

      this.reservation.stackedChart.data.labels = []
      this.reservation.statuses.request = 0
      this.reservation.statuses.conditional = 0
      this.reservation.statuses.confirmed = 0
      this.reservation.statuses.canceled = 0
      this.reservation.statuses.deleted = 0
      this.requestStatusReservations = 0
      this.conditionalStatusReservations = 0
      this.reservation.stackedChart.data.datasets[0].data = []
      this.reservation.stackedChart.data.datasets[1].data = []
      this.reservation.stackedChart.data.datasets[2].data = []
      this.reservation.stackedChart.data.datasets[3].data = []
      this.reservation.stackedChart.data.datasets[4].data = []

      this.$store.dispatch('fetchDashboard').then(response => {

        this.requestStatusReservations = response.data.requestStatusReservations
        this.conditionalStatusReservations = response.data.conditionalStatusReservations

        Object.keys(response.data.reservation).forEach(key => {
          this.reservation.stackedChart.data.labels.push(key)
          this.reservation.stackedChart.data.datasets[0].data.push(response.data.reservation[key].request)
          this.reservation.stackedChart.data.datasets[1].data.push(response.data.reservation[key].conditional)
          this.reservation.stackedChart.data.datasets[2].data.push(response.data.reservation[key].confirmed)
          this.reservation.stackedChart.data.datasets[3].data.push(response.data.reservation[key].canceled)
          this.reservation.stackedChart.data.datasets[4].data.push(response.data.reservation[key].deleted)

          this.reservation.statuses.request += response.data.reservation[key].request
          this.reservation.statuses.conditional += response.data.reservation[key].conditional
          this.reservation.statuses.confirmed += response.data.reservation[key].confirmed
          this.reservation.statuses.canceled -= response.data.reservation[key].canceled
          this.reservation.statuses.deleted -= response.data.reservation[key].deleted

          let min = 0
          let max = 0

          Object.keys(this.reservation.statuses).forEach(key => {
            if (key !== 'canceled' && key !== 'deleted') {
              max += this.reservation.statuses[key]
            } else {
              min += this.reservation.statuses[key]
            }
          })

          this.reservation.stackedChart.options.scales.yAxes[0].ticks.min = -1 * min
          this.reservation.stackedChart.options.scales.yAxes[0].ticks.max = max

        })

        this.$refs.reservationChart.updateChart()
        this.reservationLoading = false

        this.messages = response.data.messages
        this.galleries = response.data.galleries

        Object.keys(response.data.polls).forEach(key => {

          let data = response.data.polls[key].data
          let labels = []
          let datasets = []

          /**
           * A napok labeljeit seteljük be
           */
          Object.keys(data).forEach(dayKey => {
            labels.push(dayKey)
          })

          /**
           * Az első tömbelemből kivesszük a legendeket
           */
          let i = 0;
          Object.keys(data[Object.keys(data)[0]]).forEach(datasetKey => {
            datasets.push({
              data: [],
              label: datasetKey,
              backgroundColor: this.chartColors.polls[i],
              borderColor: 'transparent',
            })
            i++
          })

          Object.keys(data).forEach(dayKey => {
            Object.keys(data[dayKey]).forEach((chartDataKey, index) => {
              datasets[index].data.push(data[dayKey][chartDataKey])
            })
          })

          this.polls.push({
            id: response.data.polls[key].id,
            title: response.data.polls[key].title,
            chart: {
              data: {
                labels: labels,
                datasets: datasets,
              },
              options: {
                elements: {
                  rectangle: {
                    borderWidth: 2,
                    borderSkipped: 'bottom',
                  },
                },
                hover: {
                  mode: 'label',
                },
                responsive: true,
                maintainAspectRatio: false,
                responsiveAnimationDuration: 500,
                legend: {
                  display: true,
                  position: 'top',
                  align: 'start',
                  labels: {
                    usePointStyle: true,
                    padding: 25,
                    boxWidth: 9,
                  },
                },
                tooltips: {
                  // Updated default tooltip UI
                  shadowOffsetX: 1,
                  shadowOffsetY: 1,
                  shadowBlur: 8,
                  shadowColor: this.chartColors.tooltipShadow,
                  backgroundColor: $themeColors.light,
                  titleFontColor: $themeColors.dark,
                  bodyFontColor: $themeColors.dark,
                },
                scales: {
                  xAxes: [
                    {
                      display: true,
                      stacked: true,
                      gridLines: {
                        display: true,
                        color: this.chartColors.gridLineColor,
                        zeroLineColor: this.chartColors.gridLineColor,
                      },
                      scaleLabel: {
                        display: false,
                      },
                      ticks: {
                        fontColor: this.chartColors.labelColor,
                      },
                    },
                  ],
                  yAxes: [
                    {
                      display: true,
                      stacked: true,
                      scaleLabel: {
                        display: true,
                      },
                      gridLines: {
                        color: this.chartColors.gridLineColor,
                        zeroLineColor: this.chartColors.gridLineColor,
                      },
                      ticks: {
                        stepSize: 1,
                        fontColor: this.chartColors.labelColor,
                      },
                    },
                  ],
                },
              },
              plugins: [
                // to add spacing between legends and chart
                {
                  beforeInit(chart) {
                    /* eslint-disable func-names, no-param-reassign */
                    chart.legend.afterFit = function () {
                      this.height += 20
                    }
                    /* eslint-enable */
                  },
                },
              ],
            }
          })
        })

        this.pollsAreLoading = false
      })
    },
  }
}
</script>

<style>

</style>
